import BaseDashboard from "./components/BaseDashboard/BaseDashboard";
import BaseNavBar from "./components/BaseNavbar/BaseNavBar";
import "./App.css";

function App() {
  return (
    <>
      <BaseNavBar />
      <BaseDashboard />
    </>
  );
}

export default App;
