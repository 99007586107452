import React, { useEffect, useState } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { functions, db } from '../../firebase/firebase';
import BaseStatisticSegment from './BaseStatisticSegment';

const BaseDashboard = () => {
  const [dashboardResult, setDashboardResult] = useState({
    peopleAssisted: 0,
    drugsIntoxicated: 0,
    alone: 0,
    sexualAssaultRisk: 0,
    deEscalatedViolence: 0,
    welfareChecks: 0,
    reconnections: 0,
    escorted: 0,
    firstAid: 0,
    volunteersHours: 0,
    volunteers: 0,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const generateDashboardInfo = functions.httpsCallable(
          'httpsGenerateDashboardInfo'
        );

        const dashboardData = await db.ref().child('dashboardInfoStats').get();
        setDashboardResult(dashboardData.val());
        setLoading(false);
        const result = await generateDashboardInfo();
        if (!result) {
          setLoading(false);
          console.log(
            'Error calling cloud function httpsGenerateDashboardInfo'
          );
        }
      } catch (error) {
        console.log('Error: ', error);
      }
    })();
  }, []);

  return (
    <Grid container className='paddingTop'>
      <Grid.Row>
        <Header as='h1' className='sectionHeader'>
          Welcome to our Dashboard!
        </Header>
      </Grid.Row>
      <Grid.Row centered>This is the difference we've made so far!</Grid.Row>
      <Grid.Row centered>
        <Grid container stackable centered>
          <BaseStatisticSegment
            iconName='users'
            topLabel='People Assisted'
            iconColor='green'
            number={dashboardResult?.peopleAssisted ?? 0} // added because react complains that property is empty (when it specified as required) during data fetching
            loading={loading}
          />
          <BaseStatisticSegment
            iconName='beer'
            topLabel='Drugs and/or Intoxicated'
            iconColor='yellow'
            number={dashboardResult?.drugsIntoxicated ?? 0}
            loading={loading}
          />
          <BaseStatisticSegment
            iconName='male'
            topLabel='Alone'
            iconColor='blue'
            number={dashboardResult?.alone ?? 0}
            loading={loading}
          />
          <BaseStatisticSegment
            iconName='exclamation triangle'
            topLabel='Sexual Assault Risk'
            iconColor='orange'
            number={dashboardResult?.sexualAssaultRisk ?? 0}
            loading={loading}
          />
          <BaseStatisticSegment
            iconName='shield alternate'
            topLabel='De-escalated Violence'
            iconColor='purple'
            number={dashboardResult?.deEscalatedViolence ?? 0}
            loading={loading}
          />
          <BaseStatisticSegment
            iconName='clipboard list'
            topLabel='Welfare Checks'
            iconColor='olive'
            number={dashboardResult?.welfareChecks ?? 0}
            loading={loading}
          />
          <BaseStatisticSegment
            iconName='handshake'
            topLabel='Reconnections'
            iconColor='teal'
            number={dashboardResult?.reconnections ?? 0}
            loading={loading}
          />
          <BaseStatisticSegment
            iconName='shipping fast'
            topLabel='Escorted'
            iconColor='violet'
            number={dashboardResult?.escorted ?? 0}
            loading={loading}
          />
          <BaseStatisticSegment
            iconName='first aid'
            topLabel='First Aid'
            iconColor='red'
            number={dashboardResult?.firstAid ?? 0}
            loading={loading}
          />
          <BaseStatisticSegment
            iconName='clock'
            topLabel='Volunteer Hours'
            iconColor='pink'
            number={dashboardResult?.volunteersHours ?? 0}
            loading={loading}
          />
          <BaseStatisticSegment
            iconName='hand paper'
            topLabel='Volunteer Shifts'
            iconColor='brown'
            number={dashboardResult?.volunteers ?? 0}
            loading={loading}
          />
        </Grid>
      </Grid.Row>
      <Grid.Row
      // Padding Row otherwise bottom elements (buttons, tables) have no padding on mobile responsive mode
      />
    </Grid>
  );
};

export default BaseDashboard;
