import React from "react";
import { Grid, Image, Menu } from "semantic-ui-react";
import streetKindLogo from "../../assets/street-kind-logo-black.png";

const BaseNavBar = () => {
  return (
    <Menu size="huge" attached="top">
      <Grid container className="paddingVertical">
        <Grid.Column>
          <Image
            src={streetKindLogo}
            size="tiny"
            centered
            className="menuBarLogo"
          />
        </Grid.Column>
      </Grid>
    </Menu>
  );
};

export default BaseNavBar;
