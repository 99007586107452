import React from "react";
import PropTypes from "prop-types";
import { Grid, Segment, Statistic, Icon } from "semantic-ui-react";

const BaseStatisticSegment = (props) => {
  const { iconName, iconColor, number, loading, topLabel } = props; //no 'this' when in just a plain Functional Component

  return (
    <Grid.Column mobile={16} tablet={8} computer={5} textAlign="center">
      <Segment color="blue" raised loading={loading}>
        <Statistic>
          <Statistic.Label>{topLabel}</Statistic.Label>
          <Statistic.Value>
            <Icon
              name={iconName}
              size="small"
              color={iconColor}
              className="assistanceRenderedIcon"
            />
            {number}
          </Statistic.Value>
        </Statistic>
      </Segment>
    </Grid.Column>
  );
};

BaseStatisticSegment.propTypes = {
  topLabel: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  number: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default BaseStatisticSegment;
